import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import {
  Line,
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { Bar, BarChart } from "recharts";

function Chart() {
  //

  const [data, setData] = useState([]);

  const fetchApi = async () => {
    const response = await axios.get("https://js.krushnakhore.com/api.php");
    setData(response.data);
    console.log(response.data);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const damData = data.map((info) => {
    const { capacitylive, damname, capacitydead, capacitytotal, warninglevel } =
      info;
    var total = capacitylive + capacitydead;

    const percentage = (total / capacitytotal) * 100;
    const validPercentage = isNaN(percentage) ? 0 : Math.ceil(percentage);
    return { damname, percentage: validPercentage, warninglevel };
  });

  const customTooltip = ({ active, payload, lable }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-2 shadow-xl border border-stone-300/80 rounded">
          <p>
            Filled: <strong />
            {data.percentage}%
          </p>
          <p>
            Name: <strong />
            {data.damname}
          </p>
        </div>
      );
    }
  };

  return (
    <div className="w-full  h-fit items-center p-2 flex bg-[#32527b] flex-col">
      {/* <div className="absolute top-0 left-0 p-4 w-full  h-full " style={{pointerEvents:'none', backgroundRepeat:'no-repeat' ,background: `url(${dam})`, backgroundSize:'cover',backgroundPosition:'center'}}> */}
      <h1 className="text-xl font-bold m-4 text-white">Line Charts</h1>
      <div className=" bg-black bg-opacity-20 rounded-xl w-full mb-4 py-4 px-2">
        <ResponsiveContainer width="95%" height={500}>
          <LineChart width={1000} height={500} data={damData}>
            <XAxis
              dataKey="damname"
              textAnchor="end"
              angle={-45}
              tick={{ fontSize: 12 }}
              interval={0}
              height={80}
              stroke="#bfc1c2"
            />
            <YAxis domain={[0, 100]} stroke="#bfc1c2" />
            <Legend />
            <Line
              type="monotone"
              stroke="#fa8128"
              dataKey="percentage"
              fill="#fa8128"
            />
            <Tooltip content={customTooltip} />
          </LineChart>
        </ResponsiveContainer>
      </div>
        <h1 className="text-xl font-bold m-4 text-white">Bar Charts</h1>
      <div className=" bg-black bg-opacity-20 rounded-xl w-full mb-4 py-4 px-2">

        <ResponsiveContainer width="90%" height={500}>
          <BarChart width={1000} height={500} data={damData}>
            <XAxis
              dataKey="damname"
              textAnchor="end"
              angle={-45}
              tick={{ fontSize: 12 }}
              interval={0}
              height={80}
              stroke="#bfc1c2"
            />
            <YAxis domain={[0, 100]} stroke="#bfc1c2"/>
            <Tooltip content={customTooltip} cursor={{fill: '#32527b'}}/>
            <Legend />
            <Bar strokeWidth={4} radius={[10, 10, 0, 0]}  barCategoryGap={0} dataKey="percentage"  fill="#3ded97" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* </div> */}
    </div>
  );
}

export default Chart;

import React from 'react'
import Header from './Header'
import Chart from './Chart'
import Footer from './Footer';

function App() {
  return (
    <div>
      <Header/>
      <Chart/>
      <Footer/>
    </div>
  )
}

export default App;
import React from 'react'

function Header() {
  return (
    <nav className='w-full flex p-2 sticky top-0 shadow-md items-center font-medium text-2xl text-white h-14 bg-[#32527b] '>
        <ul className='flex w-full justify-around uppercase'>
            <li className='hover:border-b cursor-pointer'>Home</li>
            <li className='hover:border-b cursor-pointer'>Charts</li>
            <li className='hover:border-b cursor-pointer'>Contact</li>
        </ul>
    </nav>
  )
}

export default Header
import React from 'react'

function Footer() {
  return (
    <div className='w-full fixed left-0 bottom-0 bg-[#32527b]  flex justify-center items-center'>
        <h1>@copyrights reserved to govt</h1>
    </div>
  )
}

export default Footer